.home {
  display: flex;
  height: 117vh;
  justify-content: space-between;
  padding: 0.15rem 0.25rem;

  @media screen and (min-width: 1300px) {
    height: 116.25%;
  }

  @media screen and (min-width: 1800px) {
    height: 105.5%;
  }
}

.positions-container {
  flex: 0 0 75%;
  margin-right: 0.35rem;
  display: flex;
  flex-direction: column;
  background: $color-white;
  box-shadow: 0 1px 4px 1px rgba(0, 0, 0, 0.2);

  @media screen and (min-width: 1300px) {
    flex: 0 0 81%;
  }
}

.team-container {
  display: block;
  width: 100%;
  height: 50rem;
  flex: 1;
  margin: 0.15rem 0;
  border: 1px solid $color-text-dark;
  background-color: $color-white;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.footer-buttons {
  padding-bottom: 0.25rem;
  display: flex;
  justify-content: flex-end;
  margin-right: 1rem;
}

.tables-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.osh-table {
  width: 100%;
  margin-bottom: 0.15rem;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.links-table-osh {
  width: 100%;
  background: $color-white;
  border: 1px solid $color-text-paragraph;
  box-shadow: 1px 2px 1px rgba(0, 0, 0, 0.2);
}

.cost-table {
  width: 100%;
  padding: 0.15rem;
  margin-bottom: 0.25rem;
  background: $color-white;
  border: 1px solid $color-text-paragraph;
}

.error-message {
  text-align: center;
  color: $color-secondary;
  background-color: $color-white;
  padding: 5rem;
  margin: 2rem;
}
