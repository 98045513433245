.coe {
  margin-bottom: 0.25rem;
}

.coe-header {
  background: $color-primary;
  padding: 0.5rem 1.5rem;
  text-align: center;
  text-transform: uppercase;
  font-family: "Shell-Medium";
  font-size: 0.88rem;
}

.text-coe {
  flex: 0 0 90%;
  font-size: 0.8rem;
  font-weight: 700;
  padding: 0.15rem 0.25rem;
  text-align: justify;
}

.info {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0.25rem 1rem;
}

.icon-ball-info {
  margin-top: 0.5rem;
  display: inline-block;
  text-align: center;
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 50%;
  font-family: "Shell-heavy";
  background-color: $color-primary;
  color: $color-white;
}

.coe-text {
  font-family: "Shell-Medium";
  font-size: 1.75rem;
  font-weight: 600;
  text-align: center;
  color: $color-text-dark;
  margin-top: 0.35rem;
}

.text-color-red {
  color: $color-secondary;
}

.text-color-green {
  color: $color-code-green;
}
