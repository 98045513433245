.button {
  font-family: "Shell-Medium";
  font-size: 0.8rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.5rem 1rem;
  background-color: $color-primary;
  border: none;
  border-radius: 0;
  color: $color-text-primary;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 200ms ease;
}

.link {
  display: inline;
  font: inherit;
  text-decoration: none;
}

.button:focus {
  outline: none;
}

.button:hover,
.button:active {
  background-color: $color-text-primary;
  color: $color-primary;
}

.button--link {
  padding: 0.7rem 0.75rem;
  background-color: $color-white;
  font-size: 12px;
  font-weight: 700;
  color: $color-text-primary;
  transition: all 200ms ease;
}

.button--link:hover,
.button--link:active {
  background-color: rgba(0, 0, 0, 0.1);
  color: $color-secondary;
}

.button--inverse {
  color: $color-text-dark;
  background: $color-light-blue;
}

.button--duplicate {
  color: $color-text-dark;
  background: $color-white;
}

.button--duplicate:hover,
.button--duplicate:active {
  color: $color-white;
  background: $color-position-duplicated;
}

.button--inverse:hover,
.button--inverse:active {
  color: $color-white;
  background: #1976d2;
}

.button--danger {
  background-color: $color-white;
  color: $color-text-dark;
}

.button--danger:hover,
.button--danger:active {
  color: $color-white;
  background: $color-secondary;
}

.button:disabled,
.button:hover:disabled,
.button:active:disabled {
  background-color: $color-white;
  color: #979797;
  cursor: not-allowed;
}

.button--small {
  font-size: 0.75rem;
}

.button--big {
  font-size: 1.5rem;
}

.button--medium {
  font-size: 1rem;
}

.button--primary {
  background: $color-white;
  color: $color-text-dark;
}

.button--primary:hover,
.button--primary:active {
  color: $color-white;
  background: #1976d2;
}

.button--secondary {
  background: $color-background-body;
}

.button--inverse {
  background: $color-light-blue;
}
