.form-flex {
  display: flex;
  justify-content: space-between;

  @media screen and (min-width: 1800px) {
    justify-content:start;

    .form-flex form-group:last-child {
      margin-right: 2rem;
    }
  }
}

.bar-box {
  display: flex;
  justify-content: center;
  height: 5rem;
}

.progress-bar {
  font-size: 0.675rem;
  font-weight: bold;
  padding: 0.75rem 1.65rem 0.5rem 2.5rem;
  height: 5rem;
  width: 10.25rem;
  background: $color-background;
  clip-path: polygon(0 0, 80% 0%, 100% 50%, 80% 100%, 0 100%, 20% 50%);
  margin-left: -1.75rem;
  text-transform: uppercase;
}

.form-wrapper {
  display: table-cell;
  width: 77%;
  border: $border;
  background: $color-white;
  margin-right: 1rem;
}

.form-title {
  background: $color-primary;
  padding: 0.75rem 3.5rem;
  text-transform: uppercase;
  font-size: 1rem;
}

.form-title-font {
  font-size: 1.15rem;
  padding: 1.1rem 2.75rem;
  text-transform: none;
}

.form-main {
  padding: 0.15rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form__group {
  padding: 0.25rem 0.5rem;
  position: relative;
}

.form__label {
  position: absolute;
  top: 1.15rem;
  left: 1.25rem;
  padding: 0.9rem 0;
  font-size: 0.9rem;
  color: #63666a;
  pointer-events: none;
  transition: all 0.15s ease;
}

.not-empty {
  background-color: #fff;
  outline: 0;
  border: 1px solid #f8a94c;
}

.form__input {
  padding: 0.75rem;
  font-family: "Shell-Medium";
  font-size: 0.8rem;
  border: 1px solid $color-background;
  width: 30rem;
  border-radius: 0.2rem;
  outline: none;

  &:disabled {
    background-color: $color-background-body;
    color: $color-background-ind;
  }

  &:focus {
    background-color: #fff;
    outline: 0;
    border: 1px solid $color-mid-blue;
  }

  &:focus ~ .form__label,
  &.not-empty ~ .form__label {
    font-size: 0.7rem;
    color: #63666a;
    top: 0.05rem;
    left: 1rem;
    transition: all 0.15s ease;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
  }
}

textarea {
  background-color: transparent;
  padding: 0.75rem;
  font-family: $font-stack;
  font-size: 0.85rem;
  border: 1px solid $color-background;
  width: 30.75rem;
  border-radius: 4px;

  min-height: 5rem;
  resize: none;

  &:focus,
  &.not-empty {
    outline: 0;
    border: 1px solid #f8a94c;
  }

  &:focus ~ label,
  &.not-empty ~ label {
    font-size: 0.75rem;
    top: -1rem;
    transition: all 0.15s ease;
    background: white;
    padding-left: 5px;
    padding-right: 5px;
    padding-bottom: 0px;
  }
}

.form__select__label {
  display: block;
  padding: 0.5rem 0.1rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: #63666a;
}

.form__button__notification{
  font-weight: 900;
  color: #404040;
}

.form__group__submit {
  display: flex;
  justify-content: space-between;
  // margin-top: -3.5%;
  margin-left: 88%;
  width: 10rem;
}

.util-mar {
  margin-top: -0.4rem;
}

.form-text-area {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-message-form {
  font-size: 0.8rem;
  display: inline-block;
  padding: 0.25rem;
  color: $color-secondary;
  font-weight: 600;
  margin: 0 1rem;
  vertical-align: top;
}

.util-margin-left {
  font-size: 0.8rem;
  margin: 0.5rem 0 0 1.5rem;
  color: $color-text-dark !important;
}

.util-margin-top {
  font-size: 0.8rem;
  margin: 0.5rem 0 0 1.5rem;
}

.icon-ball {
  display: inline-block;
  text-align: center;
  width: 1.35rem;
  height: 1.35rem;
  border-radius: 50%;
  background-color: $color-primary;
  color: $color-white;
}

.background-or {
  margin: 0.75rem 4.5rem 0.5rem 1rem;
  font-style: italic;
}

.form__group__jobProfile {
  border: 0.75px solid $color-background;
  margin: 0.5rem 0;
  width: 31.25rem;
  border-radius: 0.5rem;
  padding-bottom: 0.8rem;
}

.util-padding-label {
  padding: 0.45rem -0.1rem;
}

.flex-change {
  display: flex;
  flex-direction: row;
  flex: 0 0 50%;
  align-items: center;
}

.form__input__error {
  border: 2px solid $color-secondary;
}

.optional-text {
  display: inline-block;
  font-size: 0.85rem;
  font-weight: 700;
  font-style: oblique;
  color: $color-mid-blue;
  padding-left: 0.5rem;
}

.ai-icon-border {
  margin: auto;
  text-align: center;
  //   // border: 3px solid #fbce07;
  //   // border-radius: 50%;
  //   // position: relative;
}
.ai-icon {
  height: 5rem;
  width: 5rem;
  text-align: center;
  margin: auto;
  // font-size: 1.5cm;
  // color: #404040;
}
