.container-outcome {
  display: flex;
  flex-direction: column;
  width: 99%;
  background-color: $color-white;
  border: $border;
  margin: 0.15rem auto;
}

.top-bar-outcome {
  width: 99%;
  height: 2.5rem;
  margin: 0.25rem auto;
  background-color: $color-primary;
  display: flex;
  justify-content: center;
  align-items: center;
}

.outcome-items {
  width: 99%;
  margin: 0.5rem auto;
  display: flex;
  justify-content: space-between;
}

.left-side-outcome {
  flex: 0 0 75%;
  width: 100%;
  height: 100%;
  margin-right: 0.5rem;
}

.outcome-text {
  display: inline-block;
  padding: 0.2rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: $color-text-dark;
}

.outcome-team-structure {
  height: 28rem;
  margin: 1rem 0;
  border: 2px solid $color-primary;
  overflow-y: scroll;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.outcome-team-structure-final {
  margin: 1rem 0;
  border: 2px solid $color-primary;
}

.outcome-linemanager {
  display: flex;
  justify-content: center;
  padding-left: 5.75rem;
  align-items: center;
}

.outcome-team {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin: 0.5rem 1.15rem;

  @media screen and (min-width: 1300px) {
    margin: 0.5rem 0.2rem;
  }

  @media screen and (min-width: 1800px) {
    margin: 0.5rem 1.75rem;
  }
}

.margin-util-text {
  margin-right: 4rem;
}

.outcome-left-title {
  font-family: "Shell-Medium";
  margin: 0.75rem 0.5rem;
  border-bottom: $border;
  text-transform: uppercase;
}

.outcome-left-subtitle {
  font-family: "Shell-Medium";
  margin: 0.75rem 0.5rem;
}

.outcome-left-main-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-mid-blue;
  margin: 0.75rem 0 1.5rem 0.5rem;
}
.outcome-left-main-main-title {
  font-size: 1.2rem;
  font-weight: bold;
  text-transform: uppercase;
  color: $color-mid-blue;
  margin: 0.75rem 0.5rem;
}
.outcome-left-texts {
  padding: 0;
  margin: 0;
  list-style: none;

  li {
    font-size: 0.75rem;
    font-weight: 600;
    padding: 0.15rem;
    margin: 0.15rem 0.5rem;
    display: flex;
    justify-content: flex-start;
  }

  strong {
    color: $color-secondary;
  }
}

.outcome-reasons {
  margin: 0.5rem;
}

.outcome-reasons-title {
  font-size: 0.8rem;
  margin: 0.55rem 1.25rem;
}

.outcome-reasons-list {
  padding: 0;
  margin: 0;

  li {
    font-size: 0.75rem;
    font-weight: 600;
    margin: 0.15rem 1rem;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
  }
}

.outcome-reason {
  font-family: "Shell-Book";
  font-size: 0.8rem;
  font-weight: 600;
  text-align: justify;
}

.extra-strong {
  font-family: "Shell-Medium";
  font-size: 0.85rem;
  font-weight: 600;
}

.outcome-left-text {
  margin-left: 1.5rem;
  padding: 0.5rem;
  font-size: 0.8rem;
  font-weight: 600;
}

.outcome-next-steps {
  border-bottom: $border;
  padding: 0.25rem;
  margin: 0 0.25rem;
}
.nested-outcome-next-steps {
  // display: block;
  display: flex;
  //   align-items: flex-start;
  font-family: "Shell-Medium";
  font-weight: 650;
  font-size: 0.825rem;
}
.nested-outcome-next-step {
  display: inline-block;
  font-family: "Shell-Medium";
  font-weight: 650;
  font-size: 0.825rem;
}
.outcome-left-nextsteps {
  list-style: none;

  li {
    font-size: 1.25rem;
    font-weight: bold;
    display: flex;
    justify-content: flex-start;
    margin: 0.25rem 0;
  }
}
.util-padding {
  padding-left: 0.5rem;
  text-align: justify;
  margin-bottom: 0.5rem;
}

.outcome-buttons {
  // border: $border;
  background-color: $color-white;
  padding: 0.75rem 0;
  display: grid;
  grid-template-columns: auto auto;
  justify-content: flex-end;
  align-items: center;
  margin: 0.15rem 0.425rem;
}

.outcome-button {
  margin: 0 1rem;
}

.outcome-text-bottom {
  display: inline-block;
  font-size: 0.8rem;
  font-weight: bold;
  margin: 1rem;

  a {
    color: $color-text-dark;
    padding: 0.45rem 0.5rem;
    font-size: 0.75rem;
    font-weight: bold;
    background-color: $color-background-body;
    margin: 0.1rem 0;
    transition: all 0.2s ease;
    &:hover {
      background-color: $color-text-dark;
      color: $color-primary;
    }
  }
}

.loader-notes {
  position: absolute;
  left: 50%;
  top: 65%;
  font-size: 1.5rem;
  color: $color-secondary;
  text-align: center;
  transform: translateX(-50%);
}

.outcome-arrow {
  vertical-align: top;
  width: 0.8rem;
  height: auto;
  display: inline-block;
  margin: 0 0.5rem;
}

.outcome-next-step {
  display: inline-block;
  font-family: "Shell-Medium";
  font-weight: 650;
  font-size: 0.825rem;
}

.outcome-bullet-arrow {
  margin: 0.15rem 0.5rem 0rem 0;
  display: inline-block;
  color: $color-secondary;
}
.nested-outcome-bullet-arrow {
  margin: 0.15rem 0.5rem 0rem 10rem;
  display: inline-block;
  color: $color-secondary;
}
.outcome-arrow-next {
  display: inline-block;
  color: $color-secondary;
  vertical-align: top;
  margin: -0.3rem 0.5rem;
}

.suggestion-text {
  color: $color-secondary;
}

.sub-text {
  font-size: 1rem;
  font-weight: bold;
  color: $color-secondary;
  margin: 0 2rem;
}
