.nav-links {
  list-style: none;
  margin: 0;
  padding-left: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.nav-links a {
  display: flex;
  height: 100%;
  box-sizing: border-box;
  color: $color-text-primary;
  text-decoration: none;
  font-weight: 700;
  font-size: 1rem;
  transition: all 200ms ease;
  padding: 0.5rem 1rem;
}

.nav-links a:hover {
  color: $color-secondary;
}

.nav-links a:active,
.nav-links a.active {
  background: $color-primary;
  color: $color-secondary;
  border-bottom: 2px solid $color-secondary;
}

.avatar {
  font-size: 1.1rem;
  font-weight: 700;
  background: $color-brown;
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  text-align: center;
  padding-top: 0.38rem;
  color: $color-white;
  margin-right: 0.75rem;
}

.avatar-title {
  padding: .5rem 0;
  font-family: "Shell-medium";
  font-size: 1rem;
  font-weight: bold;
  position: relative;
}

.drop-down__button {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 200ms ease;
  padding: 0.5rem 1.25rem;

  &:hover {
    background-color: $color-text-dark;
    color: $color-primary;
  }
}

.cursor{
  cursor: pointer;
}

.drop-down__menu-box {
  position: absolute;
  top: 3rem;
  width: 10.5rem;
  right: 0.25rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0px 3px 6px 0px rgba(0, 0, 0, 0.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  visibility: hidden;
  opacity: 0;
  margin-top: 5px;
}

.drop-down__menu {
  margin: 0;
  padding: 0 13px;
  list-style: none;
}

.drop-down__menu-box::before {
  content: "";
  background-color: transparent;
  border-right: 8px solid transparent;
  position: absolute;
  border-left: 8px solid transparent;
  border-bottom: 8px solid #fff;
  border-top: 8px solid transparent;
  top: -15px;
  right: 18px;
}

.drop-down__menu-box::after {
  content: "";
  background-color: transparent;
}

.drop-down__item {
  font-size: 1rem;
  padding: 0.75rem 1.75rem;
  text-align: left;
  font-weight: 600;
  color: $color-text-dark;
  cursor: pointer;
  position: relative;
  border-bottom: 1px solid #e0e2e9;
}

.drop-down__item:hover {
  color: $color-secondary;
}

.drop-down__item:last-of-type {
  border-bottom: 0;
}

.drop-down--active .drop-down__menu-box {
  visibility: visible;
  opacity: 1;
  margin-top: 15px;
}

.drop-down__item::before {
  content: "";
  position: absolute;
  width: 3px;
  height: 28px;
  background-color: $color-secondary;
  left: -13px;
  top: 50%;
  transform: translateY(-50%);
  display: none;
}

.drop-down__item:hover::before {
  display: block;
}
