.form__select {
  display: inline-block;
  border: 0;
  width: 30rem;
  font-family: "ShellARB-Medium";
  font-weight: 500;
  font-size: 0.75rem;
  background-color: #f8d6af;
}

.medium {
  width: 30rem;
}

.form__select__error {
  border: 1.5px solid $color-secondary;
  border-radius: 5px;
}
