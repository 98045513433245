.osh-link-icon {
  width: 2.35rem;
  height: 2.35rem;
  border-radius: 50%;
  background-color: $color-background;
  display: inline-block;
  margin-right: 1rem;

  svg {
    enable-background: new 0 0 512 512;
  }
}
