.main-navigation__nav-links {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  background-color: $color-primary;
  flex: 0 0 95%;

  @media screen and (min-width: 1300px) {
    flex: 0 0 96%;
  }

  @media screen and (min-width: 1800px) {
    flex: 0 0 97.5%;
  }
}
