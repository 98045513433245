.osh-icon {
  position: absolute;
  width: 2rem;
  height: 2rem;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 0.5rem 0.25rem;

  svg {
    enable-background: new 0 0 400 400;
  }
}
