.table-ai {
  background: #ffffff;
}
.ai-header-table {
  font-family: "Shell-Medium";
  font-size: 0.85rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem 1rem;
  margin: 0;
  background: $color-primary;
}
.ai-table {
  padding: 0.025rem 0.075rem;
}
.ai-header-row {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}
.ai-row {
  display: flex;
  background: #f7f7f7;
  padding: 0.2rem 0.4rem;
  height: 2.5rem;
  height: auto;
  margin-top: 0.1rem;
  border-left: 1px solid #ffffff;
  font-size: 0.725rem;
  align-items: center;

  &:not(:last-child) {
    margin-right: 0.025rem;
  }
}

.ai-header-parameter {
  height: auto;
  height: 2.5rem;
  width: 70%;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  align-items: center;
}

.ai-parameter {
  display: flex;
  width: 50%;
  height: auto;
  height: 2.5rem;
  text-transform: capitalize;
  font-size: 0.85rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.ai-parameter-pink {
  background: #dd1d21;
}

.ai-parameter-blue {
  background-color: #0ab15e;
}

.ai-parameter-amber {
  background-color: #eb8705;
}

.ai-parameter-width {
  width: 101%;
}
