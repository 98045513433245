.description-container {
  display: table-cell;
  width: 100%;
  background: $color-white;
}

.descriptions {
  width: 49.75%;
}

.description-optional {
  width: 32.9%;
}

.red-border {
  border: 3px solid $color-secondary;
}

.blue-border {
  border: 3px solid $color-code-green;
}

.descriptions:not(:last-child) {
  margin-right: 0.25rem;
}

.description-optional:not(:last-child) {
  margin-right: 0.25rem;
}

.description-title {
  padding: 0.25rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  margin: 0.25rem 0;
}

.content-title {
  font-size: 1rem;
}

.description-title-position {
  font-size: 0.8rem;
  text-align: center;
  padding: 0.5rem 0.25rem;
  margin: 1rem 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.26);
  cursor: pointer;
}

.description-list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.description-list-optional {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}

.description-buttons {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 1rem 0;
}

.description-button {
  text-align: right;
  margin-bottom: 1rem;
}

.loader-container {
  position: absolute;
  top: 25%;
  left: 35%;
}

.description {
  height: 35rem;
  background-color: white;
  padding: 1rem 0.35rem;
  color: #63666a;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
  overflow-y: scroll;
  overflow-x: hidden;
  cursor: pointer;

  &::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: #f5f5f5;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #555;
  }
}

.description-text {
  font-size: 0.75rem;
  display: flex;
  justify-content: space-between;
  text-align: justify;
}

.dot {
  display: inline-block;
  margin-right: 0.5rem;
  font-family: "Shell-Medium";
  font-weight: 500;
}

.dot-arrow {
  display: inline-block;
  margin-right: 0.5rem;
  font-family: "Shell-Medium";
  font-weight: 600;
  color: $color-secondary;
}

.safe {
  display: flex;
  padding: 0.1rem 0.15rem;
  font-size: 0.8rem;
  font-weight: bold;
  color: black;
}

.description-border {
  padding: 0.5rem 0.25rem;
  border: none;
  background-color: $color-light-blue;
}

.confirm-buttons {
  display: flex;
  justify-content: space-between;
  padding: 1rem 0;
}

.confirm-button {
  margin-right: 1.25rem;
}

.error-box-job-description {
  display: flex;
  align-items: center;
  width: 45%;
  margin: 5rem auto;
}

.error-message-job-description {
  color: $color-secondary;
  margin-right: 1rem;
}

.description-summary {
  margin: 0 0 10px;
  font-size: 0.825rem;
  font-weight: 600;
  color: $color-text-primary;
  padding: 0 0.75rem;

  &:not(:last-child) {
    margin-right: 5px;
    text-align: justify;
  }
}

.paragraph {
  margin: 0 0 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: #404040;
  padding: 0.75rem;
}
