.error-container {
  background: $color-white;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  text-align: center;
}

.error-outer-boundary {
  position: absolute;
  top: 50%;
  left: 20%;
  height: 20rem;
  width: 20rem;
  border-radius: 50%;
  background: $color-background-body-error;
  transform: translateY(-50%);
}

.error-title {
  position: absolute;
  top: 32%;
  left: 42%;
  font-size: 1.5rem;
  font-weight: bold;
  text-transform: uppercase;
}

.error-text {
  position: absolute;
  top: 40%;
  left: 42%;
  font-size: 1rem;
  font-weight: 650;
  color: $color-text-paragraph;

  &:last-of-type {
    margin-top: 1.5rem;
  }
}

.button-container {
  position: absolute;
  top: 50.75%;
  left: 42%;
}
