.logo-container {
  position: relative;
  height: 3.5rem;
  width: 3.5rem;
}

.logo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
}
