.links-osh {
  padding: 0.15rem;
}

.links-list-osh {
  padding-top: 0.15rem;
}

.links-header {
  font-family: "Shell-Medium";
  background: $color-primary;
  padding: 0.75rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.9rem;
}

.icon-wrapper {
  position: relative;
  display: inline-block;
  width: 2.35rem;
  height: 2.35rem;
  border-radius: 50%;
  margin-right: 0.5rem;
}

.util-text {
  font-size: 0.85rem;
  text-decoration: none;
}

.osh-link {
  display: flex;
  text-decoration: none;
  color: $color-text-primary;
  border-radius: 3rem;
}

.link-osh-one {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.15rem 0.5rem;
  margin: 0.05rem auto;
  background: $color-background-body;
  font-size: 0.75rem;
  font-weight: 600;

  &:hover {
    color: $color-primary;
    background: $color-text-primary;
  }
  &:hover .osh-link {
    color: $color-primary;
    background: $color-text-primary;
  }
}
