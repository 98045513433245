.manager-position {
  position: relative;
  width: 18rem;
  background: $color-white;
  border: 0.15px solid $color-text-dark;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  margin: 0.75rem 0.25rem;
}

.manager-details {
  position: relative;
  display: block;
  height: 10.565rem;
  margin: 0 auto;
  padding: 0.3rem 0.45rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: $color-text-primary;
  background-color: $color-light-blue;
}

.manager-text {
  display: block;
  margin: 0 auto;
  padding: 0.3rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: $color-text-primary;
}

.manager-text-job-profile {
  display: flex;
  justify-content: first baseline;
  padding: 0.3rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: $color-text-primary;
}

.job-profile-text {
  list-style: none;
  margin-left: 2rem;
}

.osh-report {
  border-top: 1px solid $color-text-dark;
  clear: right;
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  padding: 0.12rem;
}

.factor {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: $color-background-body;
  padding: 0 0 0 0.25rem;
  margin-bottom: 0.15rem;
}

.factor-title {
  color: $color-text-primary;
  font-size: 0.7rem;
  font-weight: 600;
  text-transform: uppercase;
}

.factor-value {
  display: inline-block;
  height: 100%;
  width: 3rem;
  color: $color-text-dark;
  padding: 0.5rem 0.75rem;
  font-weight: 600;
  text-align: center;
  font-size: 0.75rem;
}

.card-buttons-manager {
  border-top: 1px solid $color-text-dark;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 0.2rem 0.25rem;
  background-color: $color-white;
}

.util-1 {
  font-family: "Shell-Medium";
  font-weight: bold;
  font-size: 0.75rem;
}

.right {
  margin-left: 70%;
}

.font-reduce {
  font-size: 0.95rem;
}

.util-margin {
  margin-left: 0.15rem;
}

.align-center {
  padding-top: 0;
  padding-left: 3rem;
  font-size: 0.75rem;
}

.swal-button-manager-remove {
  font: inherit;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.5rem 1.25rem;
  background-color: $color-primary;
  border: none;
  border-radius: 0;
  color: $color-text-primary;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 200ms ease;
  outline: none;
}

.swal-button-manager-remove:hover,
.swal-button-manager-remove:active {
  background-color: #404040;
  color: $color-primary;
}

.change-button {
  font: inherit;
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.5rem 1.25rem;
  border: none;
  border-radius: 0;
  color: $color-text-dark;
  cursor: pointer;
  text-decoration: none;
  display: inline-block;
  transition: all 200ms ease;
  outline: none;
  margin-right: 1rem;
}

.confirm {
  // background-color: $color-code-green;
  background-color: $color-primary;

  &:hover {
    background-color: #404040;
    color: $color-primary;
  }
}

.warning {
  background-color: $color-primary;

  &:hover {
    background-color: #404040;
    color: $color-primary;
  }
}

.cancel {
  // background-color: $color-secondary;
  background-color: $color-primary;
  &:hover {
    // background-color: rgba(221, 29, 33, 0.6);
    background-color: #404040;
    color: $color-primary;
  }
}

.amber {
  background: $color-code-amber;
}

.green {
  background: $color-code-green;
}

.red {
  background: $color-secondary;
}

.location-box-manager {
  position: absolute;
  bottom: 0.5rem;
  right: 0.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.for-manager {
  top: 9.05rem !important;
}
