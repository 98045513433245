.down-arrow-icon {
  width: 1rem;
  height: 0.8rem;
  margin-left: 0.1rem;
   margin-top: .55rem;
  transform: rotate(180deg);
  
  svg {
    enable-background: new 0 0 21.825 21.825;
  }
}
