.team-positions {
  padding: 0.25rem 1.1rem;
  display: flex;
  justify-content: baseline;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-bottom: 0.25rem;

  @media screen and (min-width: 1300px) {
    padding: 0.25rem 0.75rem;
  }

  @media screen and (min-width: 1800px) {
    padding: 0.25rem 1.5rem;
  }
}
