.team {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2rem;
  height: 2rem;
  transform: translate(-50%, -50%);

  svg {
    enable-background: new 0 0 122.88 71.04;
  }
}

.st0 {
  fill-rule: evenodd;
  clip-rule: evenodd;
}
