$color-primary: #fbce07;
$color-secondary: #dd1d21;
$color-code-green: #0ab15e;
$color-code-amber: #eb8705;
$color-white: #ffffff;

$color-icon-box: #fde26a;

$color-light-blue: #89cfdc;
$color-mid-blue: #009eb4;
$color-dark-blue: #003c88;

$color-lilac: #ba95be;
$color-purple: #641964;
$color-sand: #ffeac2;

$color-brown: #743410;
$color-light-green: #bed50f;

$color-code-manager: $color-light-blue;
$color-position-new: $color-code-green;
$color-position-changed: #6495ed;
$color-position-duplicated: #800080;
$color-position-removed: #ff0000;
$color-position-unfilled: #f38840;

$color-dark-black: rgba(0, 0, 0, 1);
$color-text-dark: #404040;
$color-text-primary: #404040;
$color-background-ind: #7f7f7f;
$color-text-paragraph: #86888a;

$color-background-body: #f7f7f7;
$color-background-body-2: #fcfcfc;
$color-background-body-error: rgba(253, 226, 106, 0.2);
$color-background-button: #353535;

$color-background: #d9d9d9;
$color-email: #c0c0c0;

$border: 1.5px solid #d9d9d9;
$border-bottom-header: 5px solid $color-primary;
