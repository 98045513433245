.outcome-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2.15rem;
  height: 2.15rem;
  transform: translate(-50%, -50%);
  svg {
    enable-background: new 0 0 64 64;
  }
}
