.auth-container {
  position: relative;
  width: 100%;
  height: 115%;
  background-color: $color-white;
  margin: -5rem 0 0;
}

.login-logo {
  width: 10rem;
  position: absolute;
  top: 12%;
  left: 50%;
  transform: translateX(-50%);
}

.login-icon {
  height: 10rem;
  width: 10rem;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

.auth-title {
  position: absolute;
  top: 37%;
  left: 50%;
  font-size: 1.25rem;
  font-weight: bold;
  transform: translateX(-50%);
}

.auth-text {
  position: absolute;
  top: 43%;
  left: 50%;
  font-size: 1rem;
  font-weight: 650;
  color: $color-text-dark;
  transform: translateX(-50%);
  text-align: center;
}

.auth-button-container {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translateX(-50%);
}
