.container {
  display: table;
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0.35rem 0.15rem;
}

.section-welcome {
  display: table-cell;
  width: 75%;
  border: $border;
  background: $color-white;
  margin-right: 1rem;
}

.title-bar {
  display: flex;
  align-items: center;
  margin-top: 0;
  padding: 0.1rem;
  background: $color-primary;
}

.icon-box {
  width: 3rem;
  height: 3rem;
  background: $color-icon-box;
  position: relative;
  margin-right: 1rem;
}

.title-welcome {
  font-family: "Shell-Heavy";
  margin: 0 auto;
  font-size: 1.2rem;
  font-weight: 900;
}

.subtitle-welcome {
  font-family: "Shell-Medium";
  font-size: 1rem;
  font-style: italic;
  text-transform: inherit;
}

.paragraph-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.instructions {
  display: flex;
  flex-direction: column;
  padding: 1rem 1.25rem;
}

.icon-team {
  margin: 0 auto;
}

.content-title {
  margin: 0.5rem 0.75rem;
  padding-bottom: 0.5rem;
  font-size: 0.75rem;
  font-family: "Shell-Medium";
  border-bottom: $border;
  color: $color-secondary;
}
.special-content-title {
  margin: 0 0 0px 5px;
  // padding-bottom: 0.5rem;
  font-size: 1rem;
  font-family: "Shell-Medium";
  // border-bottom: $border;
  color: $color-secondary;
}
.paragraph {
  margin: 0 0 10px;
  font-size: 0.9rem;
  font-weight: 600;
  color: $color-text-primary;
  padding: 0.75rem;

  &:not(:last-child) {
    margin-right: 5px;
    text-align: justify;
  }
}

.rules-list {
  flex: 1;
  padding: 0 0.5rem;
}

.heading {
  margin: 0 0 10px;
  padding: 0.5rem 0 0.5rem 0;
  font-size: 1rem;
  color: $color-secondary;
  font-family: "Shell-Medium";
  font-weight: 700;
  border-bottom: $border;
}

.dash {
  font-size: 0.88rem;
  display: inline-block;
  margin: 0 1rem 0 0.15rem;
  font-weight: 600;
}

.bullet {
  color: $color-text-dark;
  flex: 0 0 50%;
  display: flex;
  margin: 0 5px 8px 0;
  justify-content: flex-start;
  align-items: flex-start;
}

.bullet-margin {
  margin-left: 10px;
}

.paragraph-two {
  font-family: "Shell-Book";
  margin: 0 0 0px 5px;
  font-size: 0.85rem;
  font-weight: 600;
  color: $color-text-primary;
  text-align: justify;

  &:not(:last-child) {
    margin-right: 5px;
    text-align: justify;
  }
}

.form-label-check {
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 700;
  margin-top: 0.2rem;
}

.welcome-helper-link {
  color: #404040;
  padding: 0.25rem 0.5rem;
  font-size: 0.75rem;
  font-weight: bold;
  background-color: #f7f7f7;
  margin: 0.1rem 0;
  transition: all 0.2s ease;
  &:hover {
    background-color: $color-text-dark;
    color: $color-primary;
  }
}

.swal-fire {
  width: 850px;
  border-radius: 0;
  border: 2px solid $color-primary;
}

.outcome-arrow-welcome {
  font-size: 0.8rem;
  color: $color-text-dark;
  font-weight: bold;
  margin-right: 0.5rem;
}

input[type="checkbox"] + label {
  display: block;
  margin: 0.1rem;
  cursor: pointer;
  padding: 0.1rem;
}

input[type="checkbox"] {
  display: none;
}

input[type="checkbox"] + label:before {
  content: "\2714";
  border: 0.1em solid #000;
  border-radius: 0.2em;
  display: inline-block;
  width: 1.5em;
  height: 1.75em;
  padding-left: 0.2rem;
  padding-bottom: 0.5rem;
  margin-right: 1rem;
  margin-top: 0.1rem;
  vertical-align: bottom;
  color: transparent;
  transition: 0.2s;
}

input[type="checkbox"] + label:active:before {
  transform: scale(0);
}

input[type="checkbox"]:checked + label:before {
  background-color: MediumSeaGreen;
  border-color: MediumSeaGreen;
  color: #fff;
}

input[type="checkbox"]:disabled + label:before {
  transform: scale(1);
  border-color: #aaa;
}

input[type="checkbox"]:checked:disabled + label:before {
  transform: scale(1);
  background-color: #bfb;
  border-color: #bfb;
}
.exclaim-icon{
  font-size: 1rem;
}