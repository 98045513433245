.container-changes {
  display: table;
  width: 100%;
  height: 100%;
  border-collapse: separate;
  border-spacing: 0.35rem 0.15rem;
}

.changes-container {
  position: relative;
  display: table-cell;
  width: 72%;
  border: $border;
  background: $color-white;
  margin-right: 1rem;
}

.changes {
  display: flex;
  justify-content: center;
  margin-right: 2rem;
  padding: 1rem;
}

.team-changes {
  padding: 0.25rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.changes-title {
  font-size: 1.25rem;
  padding: 2.5rem;
}

.changed-buttons {
  bottom: 1rem;
  right: 1rem;
  position: absolute;
  display: flex;
  justify-content: space-between;
}

.changed-button:not(:last-child) {
  margin-right: 1rem;
}

.changed-instructions {
  padding: 0.25rem 1rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.4);
  margin: 0.25rem 0;
}

.attachment-instruction {
  font-size: 0.88rem;
  font-weight: 600;
  padding: 1rem 2rem;
}
