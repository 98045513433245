.description-popup {
  display: grid;
  grid-template-columns: 2rem auto;
  column-gap: 1rem;
  align-items: center;
  // height: 6rem;
  
  background: white;
  padding: 1rem 0.75rem;
  cursor: pointer;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.4);
  border: 0.25px solid #c0bcbc;
  z-index: 999;
}

.description-popup-logo-container {
  position: relative;
  height: 2rem;
  width: 2rem;
  text-align: center;
  background: transparent;
  font-size: 1.25rem;
}

.description-logo {
  vertical-align: center;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.description-popup-text {
  font-family: shell-medium;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.675rem;
  color: black;
}

.extra-popup{
  padding: .5rem .75rem;
}