.links-job-evaluation {
  margin-left: 2rem;
  background: $color-white;
  display: table-cell;
  border: $border;
  height: 25rem;
}

.links-header-position {
  background: $color-primary;
  padding: .75rem;
  text-align: center;
  text-transform: uppercase;
  font-size: 1rem;
}
