.hr-icon {
  position: absolute;
  padding: 0.5rem;
  top: 50%;
  left: 50%;
  width: 3rem;
  height: 3rem;
  transform: translate(-50%, -50%);

  svg {
    enable-background: new 0 0 512 512;
  }
}
