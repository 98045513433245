.changed-position {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-background-body;
  width: 13.25rem;
  height: 8.85rem;
  border: 0.15px solid $color-text-dark;
  box-shadow: 0 0.1rem 0.2rem rgba(0, 0, 0, 1);
  margin: 0.15rem 0.275rem;
  transition: all 200ms ease;
}

.line-manager-position {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-background-body;
  width: 13.25rem;
  border: 0.15px solid $color-text-dark;
  box-shadow: 0 0.1rem 0.3rem rgba(0, 0, 0, 1);
  margin: 0.15rem 0.275rem;
  transition: all 200ms ease;
}

.background {
  background-color: $color-light-blue;
}

.outcome-card-text {
  font-size: 0.7rem;
  font-weight: 600;
  padding: 0.1rem;
}

.outcome-location-box {
  position: absolute;
  bottom: 0;
  right: 0.15rem;
  display: flex;
  padding: 0 0.1rem;
  justify-content: flex-end;
}

.hidden {
  visibility: hidden;
  opacity: 0;
}

.margin-util-2 {
  margin-left: 3.5rem;
}

.location-box-line-manager {
  display: flex;
  margin: 0.5rem 0.25rem 0 0;
  justify-content: flex-end;
}

.bold-font {
  font-family: "Shell-Medium";
  font-size: 0.725rem;
  font-weight: bold;
}

.position-alt {
  bottom: 0 !important;
}
