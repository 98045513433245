.right-side-outcome {
  width: 100%;
  height: 100%;
  flex: 0 0 1;
}

.outcome-right {
  text-align: right;
  background-color: $color-background-body;
  padding-bottom: 0.75rem;
  margin-top: 0.3rem;
}

.outcome-overall {
  display: inline-block;
  font-size: 0.8rem;
  margin-right: 0.2rem;
}

.outcome-color {
  color: $color-white;
  padding: 0.25rem 0.5rem;
  text-align: right;
  text-transform: uppercase;
  font-size: 0.8rem;
  font-weight: 600;
}

.outcome-text-right {
  display: block;
  margin-top: 0.5rem;
}

.center {
  display: block;
  width: 100%;
  text-align: center;
  padding: .75rem;
}

.margin-util-3 {
  padding-left: 0.25rem;
}

.osh-report-outcome {
  margin: 1rem auto;
}

.outcome-title-right {
  font-family: "Shell-Medium";
  font-size: 0.85rem;
  text-transform: uppercase;
  text-align: center;
  padding: 0.5rem 0.2rem;
  margin-top: 0.5rem;
  background-color: $color-primary;
}

.outcome-osh-rows {
  display: flex;
  justify-content: space-between;
  padding-top: 0.075rem;
}

.outcome-osh-row {
  flex: 0 0 15.5%;
  padding: 0.225rem 0;
  text-align: center;
  font-size: 0.75rem;
  font-weight: 700;

  &:not(:last-child) {
    margin-right: 0.1rem;
  }
}

.outcome-arrow-up {
  display: inline-block;
  font-weight: 800;
  font-size: 0.85rem;
}

.outcome-coe {
  margin: 0.5rem 0;
  background-color: $color-background-body;
}

.column-1 {
  flex: 1;
  padding-left: 0.25rem;
  text-align: left;
  font-size: 0.7rem;
  font-weight: 900;
  background-color: $color-background-body;
}

.background-yellow {
  background-color: $color-icon-box;
  color: $color-text-dark;
  font-family: "Shell-Medium";
  font-size: 0.8rem;
  font-weight: 700;
}

.red-outcome {
  background-color: $color-secondary;
  font-size: 0.8rem;
  color: black;
}

.amber-outcome {
  background-color: $color-code-amber;
  font-size: 0.8rem;
  color: black;
}

.green-outcome {
  background-color: $color-code-green;
  font-size: 0.8rem;
  color: black;
}

.outcome-text-coe {
  padding: 0.15rem 0.75rem;
  text-align: justify;
  font-size: 0.75rem;
  font-weight: 600;
}

.coe-value {
  text-align: center;
  display: block;
  font-size: 1.75rem;
  font-family: "Shell-Medium";
  margin: 5px 0;
  color: $color-text-dark;
}

.coe-value-sign {
  margin-right: 0rem;
}

.outcome-links {
  margin: 0;
  list-style: none;
}

.outcome-link {
  padding: 0.45rem 0.5rem;
  font-size: 0.5rem;
  font-weight: bold;
  background-color: $color-background-body;
  margin: 0.1rem 0;
  transition: all 0.2s ease;

  a {
    color: $color-text-dark;
  }

  &:hover {
    background-color: $color-text-dark;

    a {
      color: $color-primary;
    }
  }
}

.outcome-block {
  padding: 0 0.5rem;
}

.osh-legend-block {
  display: flex;
  padding: 0.05rem 0.25rem 0.05rem 0.25rem;
  align-items: center;
}

.osh-legends-definition {
  display: block;
  font-size: 0.7rem;
  font-weight: bold;
  text-align: left;
}

.utility-border {
  color: $color-white;
  padding: 0.35rem 0.75rem;
  margin-right: 0.25rem;
  text-align: center;
}

.outcome-legends {
  margin: 0.25rem 0 0 0;
  background-color: $color-background-body;
}

.utility-padding {
  padding: 0.35rem 1.28rem;
}

.ai-header-row-outcome{
  font-weight: 600;
  display: flex;
  justify-content: space-between;
  
}
.ai-row-outcome {
  display: flex;
  background: #f7f7f7;
  padding: 0.2rem 0.4rem;
  height: 1.75rem;
  height: auto;
  margin-top: 0.1rem;
  border-left: 1px solid #ffffff;
  font-size: 0.725rem;
  align-items: center;

  &:not(:last-child) {
    margin-right: 0.025rem;
  }
}

.ai-header-parameter-outcome {
  height: auto;
  height: 1.75rem;
  width: 68%;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
  align-items: center;
}

.ai-parameter-outcome {
  display: flex;
  width: 32%;
  height: auto;
  height: 1.75rem;
  text-transform: capitalize;
  font-size: 0.85rem;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.ai-parameter-pink {
  background: #dd1d21;
}

.ai-parameter-blue {
  background-color:#0ab15e;
}

.ai-parameter-amber {
  background-color: #eb8705;
}
