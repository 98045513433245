.individual-contributor {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: $color-background-body;
  width: 18rem;
  height: 13.4rem;
  border: 0.25px solid $color-text-dark;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  margin: 0.75rem 0.25rem;
  transition: all 200ms ease;
  padding: 0.2rem;
}

.removed-position {
  border: 4px solid $color-position-removed;
  padding: 0;
}

.new-position {
  border: 4px solid $color-position-new;
  padding: 0;
}

.duplicated-position {
  border: 4px solid $color-position-duplicated;
  padding: 0;
}

.edited-position {
  border: 4px solid $color-position-changed;
  padding: 0;
}

.individual-contributor-details {
  padding: 0.25rem;
}

.ind-cont-text {
  display: block;
  margin: 0 auto;
  padding: 0.25rem 0.75rem;
  font-size: 0.75rem;
  font-weight: 600;
  color: $color-text-primary;
}

.location-box {
  position: absolute;
  bottom: 3.5rem;
  right: 0.75rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.location-text {
  display: inline-block;
  padding-top: 0.3rem;
  font-size: 0.75rem;
  font-weight: 600;
}

.util-center {
  text-align: center;
  font-weight: bold;
}

.card-buttons-ind-cont {
  padding: 0.15rem;
}

.unfilled-tag {
  background-color: $color-position-unfilled;
  position: absolute;
  width: 4.5rem;
  height: 1.5rem;
  bottom: 48%;
  left: 0;
  padding: 0.25rem 0.35rem;
  color: $color-text-dark;
  font-size: 0.75rem;
  font-weight: bold;
  font-style: italic;
  text-transform: uppercase;
  border-top-right-radius: 2rem;
  z-index: 10;
}

.util-font-bold {
  font-family: "ShellARB-Medium";
  font-weight: bold;
}

.font-align-center {
  padding-top: 0;
  padding-left: 4.55rem;
  font-size: 0.75rem;
}

.card-blur {
  filter: blur(1.5px);
  -webkit-filter: blur(1.5px);
}

.changed-position-tag {
  font-family: "Shell-Medium";
  color: $color-position-changed;
  font-size: 1.5rem;
  position: absolute;
  top: 30%;
  right: 7%;
  z-index: 2;
}

.input_action {
  background-color: transparent !important;
  padding: 0.75rem !important;
  font-family: $font-stack !important;
  font-size: 0.85rem !important;
  border: 1px solid $color-background !important;
  border-radius: 4px !important;
  min-height: 5rem !important;
  resize: none !important;

  &:focus {
    outline: 0;
    border: 1px solid #f8a94c !important;
    box-shadow: none !important;
  }

  &::placeholder {
    color: #63666a !important;
  }
}

.position {
  bottom: 20.85%;
}

.position-is-removed {
  bottom: 20.05%;
}

.pop-up-text {
  font-style: 0.75rem;
  font-weight: 700;
  padding: 0.25rem;
}
