.table-osh {
  background: $color-white;
  // height: 100%;
  border: 1px solid $color-text-primary;
}

.header-table {
  font-family: "Shell-Medium";
  font-size: 0.85rem;
  text-transform: uppercase;
  margin: 0.15rem 0.15rem 0 0.15rem;
  padding: 0.5rem 1rem;
  text-align: center;
  background-color: $color-primary;
  text-align: center;
}

.table {
  padding: 0.025rem 0.075rem;
}

.header-head {
  font-weight: 650;
  display: flex;
  justify-content: space-between;
  font-size: 0.85rem;
}

.head {
  display: inline-block;
  background: $color-icon-box;
  padding: 0.5rem 0.65rem;
  margin-top: 0.1rem;
  border-left: 1px solid $color-white;
  font-size: 0.85rem;

  &:not(:last-child) {
    margin-right: 0.015rem;
  }
}

.header-row {
  font-weight: 600;
  display: flex;
  justify-content: space-between;
}

.row {
  display: inline-block;
  background: $color-background-body;
  padding: 0.6rem 0.4rem;
  height: 2.5rem;
  margin-top: 0.1rem;
  border-left: 1px solid $color-white;
  font-size: 0.725rem;

  &:not(:last-child) {
    margin-right: 0.025rem;
  }
}

.parameter {
  flex: 0 0 1;
  width: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}

.as-is {
  flex: 0 0 19%;
  text-align: center;
}

.to-be {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  flex: 0 0 21%;
  text-align: center;
  margin-right: 0.05rem;
}

.font-util {
  color: #000000;
  font-size: 0.9rem;
}

.same-jg-warning {
  margin: 1rem 0.5rem;
  display: flex;
  align-items: center;
  background: #edf3c4;
  padding: 0.55rem;
  border-left: 5px solid #ff8902;
  box-shadow: 0 1.5px 3px rgba(0, 0, 0, 0.6);
}

.warning-sign {
  display: inline-block;
  margin-right: 0.75rem;
}

.warning-text {
  display: inline-block;
  color: #ff8902;
  font-weight: 700;
}

.amber {
  background: $color-code-amber;
}

.green {
  background: $color-code-green;
}

.red {
  background: $color-secondary;
}
