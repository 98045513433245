.main-header {
  width: 100%;
  height: 3.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: $color-white;
  padding-left: 1rem;
  z-index: 15;
}

main {
  margin-top: 3.65rem;
  height: 89vh;
}
