.new-position-button {
  display: flex;
  justify-content: center;
  align-items: center;
  background: $color-white;
  width: 18rem;
  height: 13.4rem;
  border: 0.25px solid $color-text-dark;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.6);
  margin: 0.75rem 0.25rem;
  padding: 0 2rem;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  font-size: 0.75rem;
  color: $color-text-dark;
  cursor: pointer;
}
