input[type="file"] {
  position: absolute;
  right: -9999px;
  visibility: hidden;
  opacity: 0;
}

.upload {
  &__container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 10px auto;
  }

  &__label {
    width: 7.5rem;
    font-size: 0.8rem;
    font-weight: 600;
    position: relative;
    padding: 0.45rem 1.5rem;
    margin-right: 1.5rem;
    background-color: $color-primary;
    display: inline-block;
    overflow: hidden;
    text-align: center;
    &:hover {
      background: $color-text-dark;
      color: $color-primary;
      cursor: pointer;
      transition: 0.2s all;
    }
  }
}

.disabled-background {
  width: 7.5rem;
  background-color: $color-background-body;
  font-size: 0.85rem;
  font-weight: 600;
  position: relative;
  padding: 0.45rem 1.5rem;
  margin-right: 1.5rem;
  display: inline-block;
  overflow: hidden;
}

.files {
  display: flex;
  flex-direction: column;
  padding-top: 0.65rem;
  background-color: #ffeac2;
  width: 15rem;
  height: 3.5rem;
  text-align: center;
  justify-content: space-between;

  &__header {
    font-size: 0.8rem;
    color: var(--color-grey-dark-1);
  }

  &__list {
    display: block;
    list-style: none;
    padding: 0;
    max-height: 100px;
    overflow: auto;
    margin-left: 10px;
  }

  &__item {
    position: relative;
    display: flex;
    justify-content: space-around;
    align-items: center;

    &__name {
      display: inline-block;
      flex: 0 0 50%;
      font-size: 0.75rem;
      font-weight: bold;
      color: var(--color-primary-light);
    }

    &__size {
      display: inline-block;
      flex: 1;
      margin-left: 0.25rem;
      color: var(--color-grey-light-1);
      font-size: 0.75rem;
      font-weight: bold;
    }

    &__container {
      flex: 1;
    }
  }
}

.danger {
  color: red;
}
.error {
  display: inline-block;
  font-size: 0.75rem;
  font-weight: bold;
  color: red;
}

.upload-button {
  margin-left: 1rem;
  font-family: "Shell-Medium";
  font: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1rem;
  background-color: $color-dark-blue;
  border: none;
  color: $color-white;
  cursor: pointer;
  border-radius: 0;
  text-decoration: none;
  display: inline-block;
  transition: all 200ms ease;

  &:hover,
  &:active {
    background-color: $color-primary;
    color: $color-dark-blue;
  }
}

.jd-success-message {
  font-weight: 600;
  color: $color-code-green;
}

.jd-loading-message {
  font-weight: 600;
  color: $color-code-amber;
}

.jd-error-message {
  font-size: 0.8rem;
  font-weight: 600;
  color: $color-position-removed;
}

.loader-small {
  font-size: 10px;
  margin: 0px auto;
  text-indent: -9999em;
  width: 2.25em;
  height: 2.25em;
  border-radius: 50%;
  background: #0501f4;
  background: -moz-linear-gradient(left, #0501f4 10%, rgba(5, 1, 244, 0) 42%);
  background: -webkit-linear-gradient(
    left,
    #0501f4 10%,
    rgba(5, 1, 244, 0) 42%
  );
  background: -o-linear-gradient(left, #0501f4 10%, rgba(5, 1, 244, 0) 42%);
  background: -ms-linear-gradient(left, #0501f4 10%, rgba(5, 1, 244, 0) 42%);
  background: linear-gradient(to right, #0501f4 10%, rgba(5, 1, 244, 0) 42%);
  position: relative;
  -webkit-animation: load3 1.4s infinite linear;
  animation: load3 1.4s infinite linear;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  overflow: hidden;
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.loader-small:before {
  width: 50%;
  height: 50%;
  background: #ffeac2;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}
.loader-small:after {
  background: #ffeac2;
  width: 75%;
  height: 75%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}
@-webkit-keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes load3 {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.ai-popup {
  text-align: left;
}

.ai-header {
  text-align: left;
  font-weight: bold;
  color: $color-text-dark;
  font-size: 1.125rem;
  margin: 1rem 0;
}

.ai-title {
  font-size: 1.125rem;
  color: #404040;
  font-weight: bold;
  margin: 1rem 0 0.5rem 0;
}

.ai-title-pos {
  font-size: 1rem;
  color: $color-text-dark;
  margin: 1rem 20%;
  padding-top: 2rem;

}

.ai-jg {
  font-weight: bold;
  color: $color-code-green;
  margin: 1rem 0;
}

.bars {
  display: flex;
  justify-content: flex-start;
  margin-left: 20%;
}

.bar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding-bottom: 1.5rem;
}

.bar {
  width: 50px;
  background-color: $color-code-amber;
  margin: 0.5rem 1rem;
}

.bar-title {
  font-weight: bold;
  font-size: 0.95rem;
  color: #404040;
}

.black {
  color: $color-text-dark;
  font-weight: bold;
  font-size: 1.125rem;
}
