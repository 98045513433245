.link-to {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 0.25rem;
  padding: 0.25rem 0.5rem;
  background: $color-background-body;
  transition: all 0.2s ease;

  &:hover {
    background: $color-text-primary;
  }
}

.icon-container {
  // flex: 0 0 12.75%;
  width: 2.35rem;
  height: 2.35rem;
  border-radius: 50%;
  position: relative;
  margin: 0 0.15rem;
  background: $color-background;
}

a {
  text-decoration: none;
}

.link-text,
.link-text:link,
.link-text:visited {
  display: inline-block;
  margin-left: 0.5rem;
  text-transform: uppercase;
  text-align: left;
  color: $color-text-dark;
  font-size: 0.8rem;
  font-weight: 600;
  overflow-wrap: break-word;
}

.link-to:hover .link-text {
  color: $color-primary;
}

.icon-container-jd {
  // flex: 0 0 12.75%;
  width: 2.25rem;
  height: 2.25rem;

  border-radius: 50%;
  position: relative;
  background: $color-icon-box;
}
