$font-stack: "Shell-Book", "ShellARB-Medium", "ShellARB-Book", "Shell-Medium",
  "Shell-Bold", "ShellARB-Light", "ShellARB-Bold", "Shell-Light", "Shell-Icons";

* {
  margin: 0;
  padding: 0;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  box-sizing: border-box;
  font-family: $font-stack;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 66.7%; //1rem=10px, 10px/16px=62.5%;12pt
  background-color: $color-background-body;
  color: $color-text-primary;
}
