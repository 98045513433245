.section-contacts {
  margin-left: 2rem;
  background: $color-white;
  display: table-cell;
  border: $border;
}

.title-links {
  margin: 0 auto;
  font-family: "Shell-Heavy";
  font-size: 1rem;
  text-transform: uppercase;
}

.buttons {
  margin: 0.75rem 0;
  padding-left: 1.15rem;
}

.form {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 0 0 60%;
}

.form-label {
  font-size: 0.8rem;
  font-weight: 700;
}

.mar-top {
  padding-top: 0.75rem;
}

.mar-left-util {
  margin-left: 0.15rem;
}

.util-bgc {
  background: $color-primary;
}
