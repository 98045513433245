.tool-tip {
  position: relative;
}

.hide-tool-tip {
  display: none;
}

.tooltip-trigger {
  display: inline-block;
  text-decoration: none;
}

.tooltip-bubble {
  // min-width: 19.5rem;
  max-width: 50rem;
  position: absolute;
  z-index: 10;
  // border: 1px solid $color-text-dark;
  &::after {
    content: "";
    position: absolute;
  }
}

.tooltip-left {
  top: 50%;
  right: 100%;
  padding-right: 9px;
  transform: translateY(-50%);

  &::after {
    border-left: 9px solid $color-text-dark;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

.tooltip-right {
  top: 50%;
  left: 100%;
  padding-left: 9px;
  transform: translateY(-50%);

  &::after {
    border-right: 9px solid $color-text-dark;
    border-top: 9px solid transparent;
    border-bottom: 9px solid transparent;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
  }
}

.tooltip-top {
  bottom: 100%;
  left: 50%;
  padding-bottom: 9px;
  transform: translateX(-18%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid $color-text-dark;
    bottom: 0;
    left: 18%;
    transform: translateX(-50%);
  }
}

.tool-tip-right {
  bottom: 100%;
  right: -40%;
  padding-bottom: 9px;
  transform: translateX(-18%);

  &::after {
    border-left: 9px solid transparent;
    border-right: 9px solid transparent;
    border-top: 9px solid $color-text-dark;
    bottom: 0;
    right: 10%;
    transform: translateX(-50%);
  }
}

.tooltip-message {
  background-color: $color-text-dark;
  border-radius: 3px;
  color: $color-white;
  font-size: 0.75rem;
  font-weight: 700;
  padding: 0.75em 0.5rem;
  text-transform: initial;
  display: flex;
  align-items: center;
  width: 20rem;

  img {
    width: 4rem;
    height: 4rem;
    display: inline-block;
    margin-right: 0.5rem;
  }
}

.tooltip-width {
  width: 15rem;
}
