.manager-container {
  padding-top: 0.05rem;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.legends {
  padding: 0 1rem;
  flex: 0 0 25%;
  margin-right: -11rem;
}

.legend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
}

.manager {
  background: $color-light-blue !important;
}

.individual {
  background: $color-background-body;
}

.new {
  background: $color-code-green;
}

.changed {
  background: $color-position-changed;
}

.duplicated {
  background: $color-position-duplicated;
}

.removed {
  background: $color-position-removed;
}

.legend-box {
  display: inline-block;
  height: 1rem;
  width: 1rem;
  margin-right: 0.75rem;
  border: 0.5px solid $color-text-dark;
}

.legend-title {
  font-size: 0.7rem;
  font-weight: 700;
  text-transform: uppercase;
}

.line-manager-card {
  width: 18rem;
  padding: 0.05rem 0.5rem;
  background: $color-light-blue;
  border: 0.25px solid $color-text-dark;
  display: flex;
  justify-content: space-between;
  margin: 0.5rem 0 0.5rem 16.2rem;
  position: relative;
}

.legends-positions {
  margin-left: 8rem;
}

.legend-text {
  width: 15rem;
  font-size: 0.88rem;
  font-weight: bold;
  color: $color-text-dark;
  text-align: left;
  margin-left: 4rem;
}

.line-manager-details {
  width: 100%;
  display: block;
  height: 8rem;
  color: $color-text-primary;
  background-color: $color-light-blue;
}

.line-manager-text {
  display: block;
  margin: 0 auto;
  padding: 0.25rem;
  font-size: 0.8rem;
  font-weight: 600;
  color: $color-text-primary;
}

.util-margin {
  display: block;
  float: right;
  padding: 0.25rem 0.35rem;
}

.location-box-linemanager {
  position: absolute;
  bottom: 0;
  right: 0.15rem;
  display: flex;
  padding: 0.1rem;
  justify-content: flex-end;
}

.font-align-center-lm {
  padding-top: 0;
  padding-left: 4.45rem;
  font-size: 0.75rem;
}

.removed-position-border {
  border: 3px solid $color-position-removed;
}

.new-position-border {
  border: 3px solid $color-position-new;
}

.duplicated-position-border {
  border: 3px solid $color-position-duplicated;
}

.changed-position-border {
  border: 3px solid $color-position-changed;
}
