@font-face {
  font-family: "Shell-Book";
  src: local("Shell-Book"), url("../fonts/Shell-Book.woff") format("woff"),
    url("../fonts/Shell-Book.woff2") format("woff2"),
    url("../fonts/mobile/Shell-Book.ttf") format("ttf");
}

@font-face {
  font-family: "Shell-Bold";
  src: local("Shell-Bold"), url("../fonts/Shell-Bold.woff") format("woff"),
    url("../fonts/Shell-Bold.woff2") format("woff2"),
    url("../fonts/mobile/Shell-Bold.ttf") format("ttf");
}

@font-face {
  font-family: "Shell-Heavy";
  src: local("Shell-Heavy"), url("../fonts/Shell-Heavy.woff") format("woff"),
    url("../fonts/Shell-Heavy.woff2") format("woff2"),
    url("../fonts/mobile/Shell-Heavy.ttf") format("ttf");
}

@font-face {
  font-family: "Shell-Medium";
  src: local("Shell-Medium"), url("../fonts/Shell-Medium.woff") format("woff"),
    url("../fonts/Shell-Medium.woff2") format("woff2"),
    url("../fonts/mobile/Shell-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "ShellARB-Book";
  src: local("ShellARB-Book"), url("../fonts/ShellARB-Book.woff") format("woff"),
    url("../fonts/ShellARB-Book.woff2") format("woff2"),
    url("../fonts/mobile/ShellARB-Book.ttf") format("ttf");
}

@font-face {
  font-family: "ShellARB-Light";
  src: local("ShellARB-Light"),
    url("../fonts/ShellARB-Light.woff") format("woff"),
    url("../fonts/ShellARB-Light.woff2") format("woff2"),
    url("../fonts/mobile/ShellARB-Light.ttf") format("ttf");
}

@font-face {
  font-family: "ShellARB-Medium";
  src: local("ShellARB-Medium"),
    url("../fonts/ShellARB-Medium.woff") format("woff"),
    url("../fonts/ShellARB-Medium.woff2") format("woff2"),
    url("../fonts/mobile/ShellARB-Medium.ttf") format("ttf");
}

@font-face {
  font-family: "Shell-Icons";
  src: local("Shell-Icons"), url("../fonts/Shell-Icons.ttf") format("ttf");
}
